// src/providers/SlideUpManager.js

import React, { createContext, useContext, useReducer, useCallback } from 'react';

// Define slide-up types and priorities
export const SLIDEUP_TYPES = {
  PRIORITY: 'PRIORITY',
  INFO: 'INFO',
  STANDARD: 'STANDARD'
};

export const SLIDEUP_IDS = {
  FEEDLY: 'feedly',
  LOCAL_SEARCH: 'localSearch',
  TWITTER: 'twitter',
  COMPOSE_PODCAST: 'compose_podcast',
  ACCOUNTS: 'accounts'
};

// Initial state for the slide-up manager
const initialState = {
  activeSlideUps: [],
  // Store which slideups are currently visible
  visibleSlideUps: {},
  // Track screen size for responsive behavior
  isMobile: window.innerWidth <= 768
};

// Action types
const ACTIONS = {
  SHOW_SLIDEUP: 'SHOW_SLIDEUP',
  HIDE_SLIDEUP: 'HIDE_SLIDEUP',
  SET_MOBILE: 'SET_MOBILE'
};

// Reducer to handle slide-up state changes
const slideUpReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SHOW_SLIDEUP:
      // If showing a priority slideup, close any existing priority slideups
      if (action.payload.type === SLIDEUP_TYPES.PRIORITY) {
        const existingPrioritySlideUp = state.activeSlideUps.find(
          s => s.type === SLIDEUP_TYPES.PRIORITY && s.id !== action.payload.id
        );

        // If on mobile, also close info slideups
        if (state.isMobile) {
          return {
            ...state,
            visibleSlideUps: {
              ...Object.fromEntries(
                Object.entries(state.visibleSlideUps).map(([id, _]) => [
                  id,
                  id === action.payload.id
                ])
              ),
              [action.payload.id]: true,
              ...(existingPrioritySlideUp ? { [existingPrioritySlideUp.id]: false } : {})
            },
            activeSlideUps: [
              ...state.activeSlideUps.filter(
                slideUp => 
                  (slideUp.type === SLIDEUP_TYPES.PRIORITY && slideUp.id === action.payload.id) || 
                  slideUp.type === SLIDEUP_TYPES.INFO
              ),
              action.payload
            ]
          };
        }

        // On desktop, just close the existing priority slideup
        return {
          ...state,
          visibleSlideUps: {
            ...state.visibleSlideUps,
            [action.payload.id]: true,
            ...(existingPrioritySlideUp ? { [existingPrioritySlideUp.id]: false } : {})
          },
          activeSlideUps: [
            ...state.activeSlideUps.filter(slideUp => slideUp.id !== existingPrioritySlideUp?.id),
            action.payload
          ]
        };
      }
      
      // Add new handling for STANDARD type
      if (action.payload.type === SLIDEUP_TYPES.STANDARD) {
        return {
          ...state,
          visibleSlideUps: {
            ...state.visibleSlideUps,
            [action.payload.id]: true
          },
          activeSlideUps: [...state.activeSlideUps, action.payload]
        };
      }
      
      // For info slideups, just add them normally
      return {
        ...state,
        visibleSlideUps: {
          ...state.visibleSlideUps,
          [action.payload.id]: true
        },
        activeSlideUps: [...state.activeSlideUps, action.payload]
      };

    case ACTIONS.HIDE_SLIDEUP:
      return {
        ...state,
        visibleSlideUps: {
          ...state.visibleSlideUps,
          [action.payload]: false
        },
        activeSlideUps: state.activeSlideUps.filter(
          slideUp => slideUp.id !== action.payload
        )
      };

    case ACTIONS.SET_MOBILE:
      // When switching to mobile, close info slideups if priority slideup is open
      if (action.payload && state.activeSlideUps.some(s => s.type === SLIDEUP_TYPES.PRIORITY)) {
        return {
          ...state,
          isMobile: action.payload,
          activeSlideUps: state.activeSlideUps.filter(s => s.type === SLIDEUP_TYPES.PRIORITY),
          visibleSlideUps: Object.fromEntries(
            Object.entries(state.visibleSlideUps).map(([id, value]) => [
              id,
              value && state.activeSlideUps.find(s => s.id === id)?.type === SLIDEUP_TYPES.PRIORITY
            ])
          )
        };
      }
      return {
        ...state,
        isMobile: action.payload
      };

    default:
      return state;
  }
};

// Create context
const SlideUpContext = createContext(null);

// Provider component
export const SlideUpProvider = ({ children }) => {
  const [state, dispatch] = useReducer(slideUpReducer, initialState);

  // Handle window resize
  React.useEffect(() => {
    const handleResize = () => {
      dispatch({
        type: ACTIONS.SET_MOBILE,
        payload: window.innerWidth <= 768
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const showSlideUp = useCallback((id, type) => {
    dispatch({
      type: ACTIONS.SHOW_SLIDEUP,
      payload: { id, type }
    });
  }, []);

  const hideSlideUp = useCallback((id) => {
    dispatch({
      type: ACTIONS.HIDE_SLIDEUP,
      payload: id
    });
  }, []);

  // Calculate positions for visible slideups
  const getSlideUpStyle = useCallback((id) => {
    const { isMobile, activeSlideUps } = state;
    const slideUp = activeSlideUps.find(s => s.id === id);
    
    if (!slideUp) return {};

    if (isMobile) {
      return {
        right: 0,
        left: 0,
        margin: '0'
      };
    }

    // On desktop
    if (slideUp.type === SLIDEUP_TYPES.PRIORITY || slideUp.type === SLIDEUP_TYPES.STANDARD) {
      return {
        right: '20px',
        left: 'auto',
        margin: '0 0 30px 0'
      };
    }

    // Info slideups position - place next to priority slideup if present
    const prioritySlideUp = activeSlideUps.find(
      s => s.type === SLIDEUP_TYPES.PRIORITY && state.visibleSlideUps[s.id]
    );
    
    if (prioritySlideUp) {
      // Get position of this info slideup in the sequence of info slideups
      const infoSlideUps = activeSlideUps.filter(
        s => s.type === SLIDEUP_TYPES.INFO && state.visibleSlideUps[s.id]
      );
      const position = infoSlideUps.findIndex(s => s.id === id);
      const slideUpWidth = 297; // Width of a slideup
      const gap = 40; // Increased gap to 40px for more separation

      return {
        right: `${slideUpWidth + gap}px`, // Position right next to the priority slideup with larger gap
        left: 'auto',
        margin: '0 0 30px 0',
        transition: 'right 0.3s ease-in-out'
      };
    }

    // If no priority slideup is visible, position on the right
    return {
      right: '20px',
      left: 'auto',
      margin: '0 0 30px 0'
    };
  }, [state]);

  return (
    <SlideUpContext.Provider
      value={{
        visibleSlideUps: state.visibleSlideUps,
        isMobile: state.isMobile,
        showSlideUp,
        hideSlideUp,
        getSlideUpStyle
      }}
    >
      {children}
    </SlideUpContext.Provider>
  );
};

// Custom hook for using the slide-up context
export const useSlideUp = () => {
  const context = useContext(SlideUpContext);
  if (!context) {
    throw new Error('useSlideUp must be used within a SlideUpProvider');
  }
  return context;
};

export default SlideUpProvider;