import { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLoadingAudio } from './useLoadingAudio';
import {
  setPlayingState,
  setError,
  selectIsPlaying,
  selectCurrentPodcast,
} from '../../../redux/store';
import { v4 as uuidv4 } from 'uuid';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : 'https://backend-dev.podsi.xyz';

const DEBUG_MODE = false;

const debugLog = (...args) => {
  if (DEBUG_MODE) {
    console.log(...args);
  }
};

export const usePodcastPlayback = (getCurrentSearchType, getPodcastOrder) => {
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const pendingMainPodcastRef = useRef(null);
  const [loadingPodcastId, setLoadingPodcastId] = useState(null);
  
  const isPlaying = useSelector(selectIsPlaying);
  const currentPodcast = useSelector(selectCurrentPodcast);

  const {
    playLoadingAudio,
    stopLoadingAudio,
    setMainPodcastReady,
    status: loadingAudioStatus,
  } = useLoadingAudio();

  const formatArticlesForTTS = useCallback((articles) => {
    return articles.map(article => ({
      title: article.title,
      published: article.published || article.date || new Date().toISOString(),
      source: article.source || "Unknown Source",
      link: article.link || article.url,
      _id: article.id || uuidv4(),
      content: article.content || article.snippet,
      imageUrl: article.imageUrl || article.image
    }));
  }, []);

  const generatePodcastTTS = useCallback(async (podcast, articles) => {
    try {
      const searchType = getCurrentSearchType();
      let ttsEndpoint;
      let requestBody;
  
      if (searchType === 'compose' && podcast.type === 'mainPodcast') {
        ttsEndpoint = `${API_BASE_URL}/api/v1/compose-podcast/tts`;
        
    // Map mainPodcast type to appropriate TTS type
    const ttsType = podcast.type === 'mainPodcast' ? (
        podcast.format === 'conversational' ? 'conversational' :
        podcast.format === 'interview' ? 'interview' :
        podcast.format === 'narrative' ? 'narrative' :
        podcast.format === 'educational' ? 'educational' :
        'general' // Fallback type
    ) : podcast.type;
        
        // Clean parameter values - strip out descriptions and get just the value
        const cleanAudience = podcast.audience?.split(' ')[0] || 'general';
        const cleanDuration = podcast.duration?.split(' ')[0] || 'medium';
        const cleanTone = podcast.tone?.split(' ')[0] || 'professional';
        
        requestBody = {
          podcast: {
            headline: podcast.headline || podcast.title,
            show_notes: podcast.show_notes,
            articles: articles.map(article => ({
              title: article.title,
              url: article.url || article.link,
              content: article.content || article.snippet,
              source: article.source,
              type: article.type,
              instructions: podcast.instructions,
              audience: cleanAudience,
              duration: cleanDuration,
              tone: cleanTone
            })),
            type: podcast.type,
            format: podcast.format,
            instructions: podcast.instructions
          },
          parameters: {
            type: ttsType,
            audience: cleanAudience,
            duration: cleanDuration,
            tone: cleanTone
          },
          ttsOptions: {
            intro: { enabled: false },
            outro: { enabled: false },
            backgroundMusic: { enabled: false }
          }
        };
      } else if (searchType === 'pdf') {
        // Existing PDF logic
        ttsEndpoint = `${API_BASE_URL}/api/v1/podcast/tts`;
        const encodedTextUrl = encodeURI(podcast.textUrl || '');
        const isPdfConversational = podcast.type === 'conversationalPodcast';
        
        requestBody = {
          textUrl: encodedTextUrl,
          showNotes: podcast.show_notes,
          ttsStyle: isPdfConversational ? 'conversational' : 'solo',
          intro: { enabled: false },
          outro: { enabled: false },
          backgroundMusic: { enabled: false }
        };
      } else {
        // Handle both dynamic news and non-mainPodcast compose types
        ttsEndpoint = `${API_BASE_URL}/api/v1/podcasts/search/dynamic-news/tts`;
        requestBody = {
          news: formatArticlesForTTS(articles),
          ttsStyle: podcast.type === 'conversationalPodcast' ? 'conversational' : 'solo',
          intro: { enabled: false },
          outro: { enabled: false },
          backgroundMusic: { enabled: false }
        };
      }
  
      debugLog('TTS request:', { endpoint: ttsEndpoint, body: requestBody });
  
      const response = await fetch(ttsEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      
      if (!data.status === 'success' || !data.data) {
        throw new Error('Invalid response format from TTS service');
      }
  
      return data.data;
    } catch (error) {
      debugLog('TTS generation error:', error);
      throw error;
    }
  }, [getCurrentSearchType, formatArticlesForTTS]);

  const handlePodcastPlay = useCallback(async (podcast, getFilteredArticles) => {
    if (!mounted.current || !podcast?.id) return;
    
    if (currentPodcast?.id === podcast.id) {
      dispatch(setPlayingState(!isPlaying));
      return;
    }
    
    try {
      setLoadingPodcastId(podcast.id);
      pendingMainPodcastRef.current = podcast;
      
      const searchType = getCurrentSearchType();
      const isPdfConversational = searchType === 'pdf' && podcast.type === 'conversationalPodcast';
      
      let articles = [];
      if (!isPdfConversational && typeof getFilteredArticles === 'function') {
        articles = getFilteredArticles(podcast);
        if (!articles.length && searchType !== 'pdf') {
          throw new Error('No articles found for this podcast');
        }
      }

      await playLoadingAudio(podcast);
      const audioUrl = await generatePodcastTTS(podcast, articles);
      
      if (!mounted.current) return;

      debugLog('Main podcast generated, signaling ready');
      setMainPodcastReady(audioUrl, {
        ...podcast,
        order: getPodcastOrder(podcast.type)
      });

    } catch (error) {
      debugLog('Error playing podcast:', error);
      if (mounted.current) {
        dispatch(setError(error.message || 'Failed to play podcast'));
        dispatch(setPlayingState(false));
        stopLoadingAudio();
      }
    } finally {
      if (mounted.current) {
        setLoadingPodcastId(null);
      }
    }
  }, [
    dispatch,
    generatePodcastTTS,
    currentPodcast,
    isPlaying,
    playLoadingAudio,
    stopLoadingAudio,
    setMainPodcastReady,
    getCurrentSearchType,
    getPodcastOrder
  ]);

  const handlePodcastPause = useCallback(() => {
    dispatch(setPlayingState(false));
  }, [dispatch]);

  return {
    loadingPodcastId,
    setLoadingPodcastId,
    loadingAudioStatus,
    handlePodcastPlay,
    handlePodcastPause,
    isPlaying,
    currentPodcast,
    stopLoadingAudio,
  };
};