// src/components/slideups/ComposePodcastSlideUp.js

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Mic } from 'lucide-react';
import { useSlideUp, SLIDEUP_IDS, SLIDEUP_TYPES } from '../../providers/SlideUpManager';
import { setComposeParameters } from '../../redux/store';

const DropdownButton = ({ value, options, onChange }) => (
 <button
   onClick={() => onChange(options[(options.indexOf(value) + 1) % options.length])}
   className="inline-flex items-center justify-center px-3 py-1 mx-1 text-sm border border-gray-300 rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-200"
   style={{
     backgroundColor: 'white',
     color: '#1a1a1a',
     transition: 'all 0.2s ease'
   }}
 >
   {value}
 </button>
);

const ComposePodcastSlideUp = ({ isVisible, onClose }) => {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const [isMinimized, setIsMinimized] = React.useState(false);
 const { getSlideUpStyle } = useSlideUp();
 const slideUpStyle = getSlideUpStyle(SLIDEUP_IDS.COMPOSE_PODCAST);

 // Form state with fixed default values
 const [type, setType] = useState('conversational');
 const [audience, setAudience] = useState('general audience');
 const [duration, setDuration] = useState('medium');
 const [tone, setTone] = useState('semi-professional');

 // Dropdown options
 const typeOptions = ['conversational', 'interview', 'narrative', 'educational'];
 const audienceOptions = ['general audience', 'technical audience', 'beginner audience', 'expert audience'];
 const durationOptions = ['short', 'medium', 'long'];
 const toneOptions = ['casual', 'semi-professional', 'professional', 'formal'];

 const toggleMinimize = () => {
   setIsMinimized(!isMinimized);
 };

 // Calculate height based on screen size
 const getHeight = () => {
   const isMobile = window.innerWidth <= 768;
   return {
     expanded: isMobile ? '570px' : '449px',
     minimized: '67px'
   };
 };

 // Update Redux whenever settings change
 useEffect(() => {
   if (isVisible) {
     const parameters = {
       type,
       audience,
       duration,
       tone
     };
     
     // Log the update for debugging
     console.log('Updating compose parameters:', parameters);
     
     dispatch(setComposeParameters(parameters));
   }
 }, [type, audience, duration, tone, isVisible, dispatch]);

 // Create handlers for each parameter change
 const handleTypeChange = (newType) => {
   setType(newType);
   dispatch(setComposeParameters({
     type: newType,
     audience,
     duration,
     tone
   }));
 };

 const handleAudienceChange = (newAudience) => {
   setAudience(newAudience);
   dispatch(setComposeParameters({
     type,
     audience: newAudience,
     duration,
     tone
   }));
 };

 const handleDurationChange = (newDuration) => {
   setDuration(newDuration);
   dispatch(setComposeParameters({
     type,
     audience,
     duration: newDuration,
     tone
   }));
 };

 const handleToneChange = (newTone) => {
   setTone(newTone);
   dispatch(setComposeParameters({
     type,
     audience,
     duration,
     tone: newTone
   }));
 };

 return (
   <div
     className={`fixed md:w-[297px] w-full ${isVisible ? 'block' : 'hidden'}`}
     style={{
       ...slideUpStyle,
       height: isMinimized ? getHeight().minimized : getHeight().expanded,
       filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))',
       zIndex: 999,
       backgroundColor: 'white',
       bottom: isVisible ? '0' : `-${getHeight().expanded}`,
       borderRadius: window.innerWidth > 768 ? '10px' : '10px 10px 0 0',
       transition: 'height 0.3s ease-in-out'
     }}
   >
     {/* Header Section with Gradient */}
     <div
       className="w-full"
       style={{
         position: 'relative',
         height: '67px',
         background: 'linear-gradient(white 0%, white 18.2%, rgba(255, 255, 255, 0.86) 68.5%, rgba(255, 255, 255, 0) 100%)',
         borderTopLeftRadius: '16px',
         borderTopRightRadius: '16px',
         display: 'flex',
         justifyContent: 'space-between',
         alignItems: 'flex-start',
         padding: '16px',
         paddingTop: '8px',
         zIndex: '9',
       }}
     >
       {/* Compose Podcast Title with Icon */}
       <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
         <Mic className="w-4 h-4" color="#1a1a1a" />
         <span style={{
           color: '#1a1a1a',
           fontWeight: 'bold',
           fontSize: '18px',
           fontFamily: 'Inter, sans-serif',
         }}>
           Compose Podcast
         </span>
       </div>
       {/* Control Buttons */}
       <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
         {/* Minimize Button */}
         <div
           style={{
             width: '32px',
             height: '32px',
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             cursor: 'pointer',
             color: '#1a1a1a',
           }}
           onClick={toggleMinimize}
         >
           <span style={{ 
             fontSize: '24px',
             transform: isMinimized ? 'rotate(180deg)' : 'rotate(0deg)',
             display: 'block',
             transition: 'transform 0.3s ease'
           }}>
             ⌃
           </span>
         </div>
         {/* Close Button */}
         <div
           style={{
             width: '28px',
             height: '28px',
             borderRadius: '50%',
             border: '2px solid #1a1a1a',
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             cursor: 'pointer',
             backgroundColor: 'transparent',
           }}
           onClick={onClose}
         >
           <span style={{ 
             fontSize: '20px',
             color: '#1a1a1a', 
             lineHeight: '1',
             fontWeight: '500',
             marginTop: '-2px'
           }}>×</span>
         </div>
       </div>
     </div>

     {/* Content Area - Only shown when not minimized */}
     {!isMinimized && (
       <div
         className="w-full"
         style={{
           position: 'absolute',
           top: '48px',
           bottom: '0',
           overflowY: 'auto',
           padding: '16px',
         }}
       >
         <div style={{ 
           color: '#1a1a1a',
           textAlign: 'left',
           padding: '16px',
           fontSize: '16px',
           lineHeight: '1.8'
         }}>
           Create a
           <DropdownButton
             value={type}
             options={typeOptions}
             onChange={handleTypeChange}
           />
           podcast which is tailored for a
           <DropdownButton
             value={audience}
             options={audienceOptions}
             onChange={handleAudienceChange}
           />
           with a
           <DropdownButton
             value={duration}
             options={durationOptions}
             onChange={handleDurationChange}
           />
           duration. The tone should be
           <DropdownButton
             value={tone}
             options={toneOptions}
             onChange={handleToneChange}
           />.
         </div>
       </div>
     )}
   </div>
 );
};

export default ComposePodcastSlideUp;