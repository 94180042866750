import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ArticleCard, ArticleCardSkeleton } from '../../cards';
import { useBookmarks } from '../hooks/useBookmarks';
import { useSortedContent } from '../hooks/useSortedContent';
import {
  selectArticles,
  selectFeedlyArticles,
  selectIsFeedlyContent,
  selectOrganicResults,
  selectSortOrder,
  selectSearchType,
  selectSearchParameters
} from '../../../redux/store';

// Helper function to get time difference
const getRelativeTime = (timestamp) => {
  try {
    if (!timestamp) return '';

    // Convert timestamp to milliseconds if it's in seconds
    const timeInMs = timestamp.toString().length === 10 
      ? timestamp * 1000 
      : timestamp;

    const now = new Date().getTime();
    const diff = now - timeInMs;
    
    // Convert diff to appropriate units
    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    
    if (minutes < 60) {
      return minutes <= 1 ? 'just now' : `${minutes} minutes ago`;
    } else if (hours < 24) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (days < 7) {
      return days === 1 ? 'yesterday' : `${days} days ago`;
    } else if (weeks < 4) {
      return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    } else {
      return months === 1 ? '1 month ago' : `${months} months ago`;
    }
  } catch (error) {
    console.error('Error calculating relative time:', error);
    return '';
  }
};

// Helper to extract domain from URL
const getDomainFromUrl = (url) => {
  try {
    const domain = new URL(url).hostname;
    return domain
      .replace('www.', '')
      .split('.')
      .slice(0, -1)
      .join('.')
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } catch {
    return '';
  }
};

const ArticleSection = ({ isLoading }) => {
  // Redux selectors with null safety
  const articles = useSelector(selectArticles) || [];
  const feedlyArticles = useSelector(selectFeedlyArticles) || [];
  const organicResults = useSelector(selectOrganicResults) || [];
  const isFeedlyContent = useSelector(selectIsFeedlyContent);
  const sortOrder = useSelector(selectSortOrder);
  const searchType = useSelector(selectSearchType);
  const searchParams = useSelector(selectSearchParameters);

  // Debug logging for state changes
  useEffect(() => {
    console.log('ArticleSection State Update:', {
      articles: articles.length,
      feedlyArticles: feedlyArticles.length,
      organicResults: organicResults.length,
      isFeedlyContent,
      searchType,
      searchParams,
      rawOrganicResults: organicResults,
      rawArticles: articles
    });
  }, [articles, feedlyArticles, organicResults, isFeedlyContent, searchType, searchParams]);

  // Determine which articles to display based on search type and content source
  const displayedArticles = React.useMemo(() => {
    console.log('ArticleSection Display Logic:', {
      isFeedlyContent,
      searchType,
      articlesLength: articles.length,
      feedlyArticlesLength: feedlyArticles.length,
      organicResultsLength: organicResults.length,
      rawOrganicResults: organicResults,
      rawArticles: articles
    });

    if (isFeedlyContent && feedlyArticles.length > 0) {
      console.log('Using Feedly Articles:', feedlyArticles);
      return feedlyArticles;
    }

    if (!isFeedlyContent && searchType === 'organic') {
      if (organicResults.length > 0) {
        console.log('Using Organic Results:', organicResults);
        return organicResults;
      }
      console.log('Falling back to Articles for Organic Search:', articles);
      return articles;
    }

    console.log('Using Default Articles:', articles);
    return articles;
  }, [articles, feedlyArticles, organicResults, isFeedlyContent, searchType]);

  const getSortedContent = useSortedContent();
  
  // Process articles with normalized dates and thumbnails
  const processedArticles = React.useMemo(() => {
    console.log('Processing Articles:', {
      displayedArticlesCount: displayedArticles.length,
      firstDisplayedArticle: displayedArticles[0],
      displayedArticles
    });

    return displayedArticles.map(article => {
      const rawTimestamp = article.published || article.date;

      // For organic results, extract source from URL if no source provided
      const source = searchType === 'organic' 
        ? getDomainFromUrl(article.link)
        : article.source || "Unknown Source";

      // Only process date if it exists
      const dateString = rawTimestamp ? getRelativeTime(
        typeof rawTimestamp === 'number' 
          ? rawTimestamp 
          : new Date(rawTimestamp).getTime()
      ) : '';

      return {
        ...article,
        title: article.title || 'Untitled Article',
        published: dateString,
        source: source,
        link: article.link || article.url,
        id: article.id || article.link || Math.random().toString(36).substr(2, 9),
        content: article.content || article.snippet || '',
        imageUrl: article.urlToImage || 
                 (article.visual && article.visual.url) || 
                 article.imageUrl || 
                 article.image
      };
    });
  }, [displayedArticles, searchType]);

  // Debug log for final processed articles
  useEffect(() => {
    console.log('Final Processed Articles:', {
      count: processedArticles.length,
      firstArticle: processedArticles[0],
      processedArticles
    });
  }, [processedArticles]);

  const { bookmarks: articleBookmarks, handleBookmark } = useBookmarks(processedArticles, 'articles');

  const handleShare = async (article) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: article.title,
          text: article.description || article.snippet,
          url: article.link || article.url
        });
      } else {
        await navigator.clipboard.writeText(article.link || article.url);
        console.log('Link copied to clipboard');
      }
    } catch (error) {
      console.error('Error sharing article:', error);
    }
  };

  return (
    <div className="mb-8">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {isLoading ? (
          Array(6).fill(0).map((_, index) => (
            <ArticleCardSkeleton key={`skeleton-${index}`} />
          ))
        ) : processedArticles.length > 0 ? (
          getSortedContent(processedArticles, sortOrder).map((article) => (
            <ArticleCard 
              key={article.id} 
              article={article}
              onBookmark={() => handleBookmark(article)}
              onShare={() => handleShare(article)}
              isBookmarked={articleBookmarks[article.id]}
            />
          ))
        ) : (
          <div className="col-span-full text-center py-8">
            <p className="text-gray-600 mb-2">
              No articles found.
            </p>
            <p className="text-gray-500 text-sm">
              Try adjusting your search terms or category selection.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleSection;