// src/components/slideups/AccountsSlideUp.js

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserCircle2 } from 'lucide-react';
import { useSlideUp, SLIDEUP_IDS, SLIDEUP_TYPES } from '../../providers/SlideUpManager';
import noIfsLogo from '../../assets/images/noIfs_Logo_white.png'

const AccountsSlideUp = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMinimized, setIsMinimized] = React.useState(false);
  const { getSlideUpStyle } = useSlideUp();
  const slideUpStyle = getSlideUpStyle(SLIDEUP_IDS.ACCOUNTS);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  // Calculate height based on screen size
  const getHeight = () => {
    const isMobile = window.innerWidth <= 768;
    return {
      expanded: isMobile ? '570px' : '449px',
      minimized: '67px'
    };
  };

  const handleWhatsNextClick = () => {
    window.open('https://dynamicpodcasts.notion.site/What-s-Next-b5ded5f5d59a47c5bd75c3fb1772d260', '_blank');
  };

  const handlePatreonClick = () => {
    window.open('https://www.patreon.com/dynamicpodcasts', '_blank');
  };

  return (
    <div
      className={`fixed md:w-[297px] w-full ${isVisible ? 'block' : 'hidden'}`}
      style={{
        ...slideUpStyle,
        height: isMinimized ? getHeight().minimized : getHeight().expanded,
        filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))',
        zIndex: 999,
        backgroundColor: 'rgb(25, 25, 25)',
        bottom: isVisible ? '0' : `-${getHeight().expanded}`,
        borderRadius: window.innerWidth > 768 ? '10px' : '10px 10px 0 0',
        transition: 'height 0.3s ease-in-out'
      }}
    >
      {/* Header Section with Gradient */}
      <div
        className="w-full"
        style={{
          position: 'relative',
          height: '67px',
          background: 'linear-gradient(rgb(25, 25, 25) 0%, rgb(25, 25, 25) 18.2%, rgba(25, 25, 25, 0.86) 68.5%, rgba(25, 25, 25, 0) 100%)',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: '16px',
          paddingTop: '8px',
          zIndex: '9',
        }}
      >
        {/* Accounts Title with Icon */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <UserCircle2 className="w-4 h-4" color="#FFFFFF" />
          <span style={{
            color: '#FFFF',
            fontWeight: 'bold',
            fontSize: '18px',
            fontFamily: 'Inter, sans-serif',
          }}>
            Accounts
          </span>
        </div>
        {/* Control Buttons */}
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {/* Minimize Button */}
          <div
            style={{
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              color: '#FFFF',
            }}
            onClick={toggleMinimize}
          >
            <span style={{ 
              fontSize: '24px',
              transform: isMinimized ? 'rotate(180deg)' : 'rotate(0deg)',
              display: 'block',
              transition: 'transform 0.3s ease'
            }}>
              ⌃
            </span>
          </div>
          {/* Close Button */}
          <div
            style={{
              width: '28px',
              height: '28px',
              borderRadius: '50%',
              border: '2px solid #FFFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: 'transparent',
            }}
            onClick={onClose}
          >
            <span style={{ 
              fontSize: '20px',
              color: '#FFFF', 
              lineHeight: '1',
              fontWeight: '500',
              marginTop: '-2px'
            }}>×</span>
          </div>
        </div>
      </div>

      {/* Content Area - Only shown when not minimized */}
      {!isMinimized && (
        <>
          <div
            className="w-full"
            style={{
              position: 'absolute',
              top: '24px',
              bottom: '65px',
              overflowY: 'auto',
              padding: '16px',
            }}
          >
            <img 
              src={noIfsLogo}
              alt="noIfs Logo"
              style={{
                width: '120px',
                height: '120px',
                display: 'block',
                margin: '0 auto 8px auto'
              }}
            />
            
            <div style={{ 
              color: '#FFFF',
              textAlign: 'left',
              padding: '0 12px',
              marginTop: '0'
            }}>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                opacity: '0.9',
                marginBottom: '12px'
              }}>
                Dynamic Podcasts is currently in experimental phase. Advanced features, including subscriptions, ability to save podcasts and a more complete experience, will be available soon.
              </p>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                opacity: '0.9',
                marginBottom: '32px'
              }}>
                Until then, you can support continued development and use of the app via Patreon.
              </p>

              <div className="flex flex-col gap-4">
                <button
                  onClick={handleWhatsNextClick}
                  className="w-full px-4 py-3 bg-white text-black rounded-lg hover:bg-gray-100 transition-colors font-medium"
                >
                  What's Next
                </button>
              </div>
            </div>
          </div>

          {/* Footer with Matching Gradient */}
          <div
            className="w-full absolute bottom-0"
            style={{
              height: '97px',
              background: 'linear-gradient(rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0.86) 31.5%, rgb(25, 25, 25) 81.8%, rgb(25, 25, 25) 100%)',
              borderBottomLeftRadius: window.innerWidth > 768 ? '16px' : '0',
              borderBottomRightRadius: window.innerWidth > 768 ? '16px' : '0',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              padding: '0 20px 14px 20px',
            }}
          >
            <button
              onClick={handlePatreonClick}
              className="w-full px-4 py-3 border border-white text-white rounded-lg hover:bg-white hover:text-black transition-colors font-medium"
            >
              Support on Patreon
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountsSlideUp;