import React, { useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PodcastCard, PodcastCardSkeleton } from '../../cards';
import { usePodcastGeneration } from '../hooks/usePodcastGeneration';
import { usePodcastPlayback } from '../hooks/usePodcastPlayback';
import { useBookmarks } from '../hooks/useBookmarks';
import {
  selectDynamicPodcasts,
  selectIsPlaying,
  selectCurrentPodcast,
  selectFeedlyUser,
  selectIsFeedlyContent,
} from '../../../redux/store';
import { ExternalLink, Volume2 } from 'lucide-react';

const DEBUG_MODE = false;

const debugLog = (...args) => {
  if (DEBUG_MODE) {
    console.log(...args);
  }
};

const PODCAST_TYPE_ORDER = {
  compose: ['conversationalPodcast', 'topicOverviewPodcast', 'trendingNewsPodcast'],
  pdf: ['conversationalPodcast'],
  url: ['conversationalPodcast', 'topNewsPodcast', 'trendingNewsPodcast', 'topicOverviewPodcast', 'urlPodcast']
};

const PodcastSection = ({ 
  articlesLoaded,
  podcastGenerationIds = {}
}) => {
  const carouselRef = useRef(null);
  const [hoveredPodcastTitle, setHoveredPodcastTitle] = useState(null);
  const [showAllDescription, setShowAllDescription] = useState(false);
  
  const dynamicPodcasts = useSelector(selectDynamicPodcasts);
  const feedlyUser = useSelector(selectFeedlyUser);
  const isFeedlyContent = useSelector(selectIsFeedlyContent);

  const searchType = new URLSearchParams(window.location.search).get('searchType');
  const isPdf = searchType === 'pdf';
  const podcastShows = Object.values(dynamicPodcasts || {}).filter(podcast => podcast && podcast.id);

  const { 
    podcastGenerationStatus,
    socketStatus,
    getCurrentSearchType,
    getPodcastOrder
  } = usePodcastGeneration(
    articlesLoaded,
    isFeedlyContent,
    feedlyUser.token,
    podcastGenerationIds
  );

  const {
    loadingPodcastId,
    setLoadingPodcastId,
    loadingAudioStatus,
    handlePodcastPlay,
    handlePodcastPause,
    isPlaying,
    currentPodcast
  } = usePodcastPlayback(getCurrentSearchType, getPodcastOrder);

  const { bookmarks: podcastBookmarks, handleBookmark } = useBookmarks(
    podcastShows, 
    'podcasts'
  );

  const getFilteredArticles = useCallback((podcast) => {
    if (isPdf) {
      if (podcast.type === 'conversationalPodcast') {
        return [];
      }
    }

    if (!podcast.articles) return [];
    return podcast.articles.filter(article => 
      article.podcastId === podcast.id || article.id
    );
  }, [isPdf]);

  const handleScroll = useCallback(() => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      const scrollPercentage = (scrollLeft / (scrollWidth - clientWidth)) * 100;
      
      carouselRef.current.style.setProperty('--left-fade', `${Math.min(scrollPercentage, 10)}%`);
      carouselRef.current.style.setProperty('--right-fade', `${Math.min(100 - scrollPercentage, 10)}%`);
    }
  }, []);

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (carouselElement) {
      carouselElement.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleShare = useCallback((podcast) => {
    console.log('Share:', podcast);
  }, []);

  const handlePodcastHover = useCallback((title) => {
    setHoveredPodcastTitle(title);
  }, []);

  const handlePlayPause = useCallback((podcast) => {
    if (currentPodcast?.id === podcast.id && isPlaying) {
      handlePodcastPause();
    } else {
      handlePodcastPlay(podcast, getFilteredArticles);
    }
  }, [currentPodcast, isPlaying, handlePodcastPlay, handlePodcastPause, getFilteredArticles]);

  const hoveredPodcast = hoveredPodcastTitle 
    ? podcastShows.find(podcast => podcast?.title === hoveredPodcastTitle)
    : null;

  const currentDescription = hoveredPodcast?.show_notes || "";

  const truncatedDescription = useCallback((description, maxLength = 150) => {
    if (description.length <= maxLength) return description;
    return showAllDescription ? description : `${description.slice(0, maxLength)}...`;
  }, [showAllDescription]);

  const getLoadingStatusText = useCallback((podcastType) => {
    const status = podcastGenerationStatus[podcastType] || {};
    const { loading = false } = status;
    if (loading) {
      if (loadingPodcastId === dynamicPodcasts[podcastType]?.id) {
        switch (loadingAudioStatus) {
          case 'generating':
            return 'Preparing preview...';
          case 'playing':
            return 'Playing preview...';
          default:
            return 'Generating podcast...';
        }
      }
      return 'Generating podcast...';
    }
    return 'Ready';
  }, [podcastGenerationStatus, loadingPodcastId, loadingAudioStatus, dynamicPodcasts]);

  const validPodcastTypes = Object.entries(podcastGenerationStatus)
    .filter(([type, status]) => {
      debugLog(`Checking podcast type ${type}:`, status);
      return !status.error;
    })
    .map(([type]) => type)
    .sort((a, b) => {
      if (searchType === 'url' || searchType === 'pdf' || searchType === 'compose') {
        const orderMap = PODCAST_TYPE_ORDER[searchType] || [];
        return orderMap.indexOf(a) - orderMap.indexOf(b);
      }
      return 0;
    });

  debugLog('Valid Podcast Types:', validPodcastTypes);

  const renderPodcast = (podcastType) => {
    console.log(`Rendering podcast ${podcastType}:`, {
      status: podcastGenerationStatus[podcastType],
      podcast: dynamicPodcasts?.[podcastType],
      loading: loadingPodcastId === podcastType
    });
    
    const status = podcastGenerationStatus[podcastType] || {};
    const { loading = false } = status;
    
    if (loading) {
      return (
        <PodcastCardSkeleton 
          key={`skeleton-${podcastType}`} 
          isPdf={isPdf}
          loadingText={`Generating ${podcastType}...`}
        />
      );
    }

    const podcast = dynamicPodcasts?.[podcastType];
    if (podcast?.id) {
      return (
        <PodcastCard
          key={podcast.id}
          podcast={podcast}
          onHover={handlePodcastHover}
          isPlaying={currentPodcast?.id === podcast.id && isPlaying}
          isLoading={loadingPodcastId === podcast.id}
          loadingStatus={loadingAudioStatus}
          onPlay={() => handlePodcastPlay(podcast, getFilteredArticles)}
          onPause={handlePodcastPause}
          onBookmark={() => handleBookmark(podcast)}
          onShare={() => handleShare(podcast)}
          isBookmarked={podcastBookmarks[podcast.id]}
          articleCount={getFilteredArticles(podcast).length}
        />
      );
    }

    return (
      <PodcastCardSkeleton 
        key={`loading-${podcastType}`}
        isPdf={isPdf}
        loadingText={`Waiting for ${podcastType}...`}
      />
    );
  };

  if (process.env.NODE_ENV === 'development') {
    debugLog("PodcastSection state:", {
      dynamicPodcasts,
      podcastShows,
      currentPodcast,
      generationStatus: podcastGenerationStatus
    });
  }

  useEffect(() => {
    console.log('Redux State:', {
      dynamicPodcasts,
      podcastGenerationStatus,
      socketStatus,
      articlesLoaded,
      podcastGenerationIds
    });
  }, [dynamicPodcasts, podcastGenerationStatus, socketStatus, articlesLoaded, podcastGenerationIds]);

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <h2 className="text-2xl font-bold">
            {isPdf ? 'PDF Dynamic Podcasts' : 'Dynamic Podcast Shows'}
          </h2>
          {validPodcastTypes.length > 0 && (
            <div className={`hidden md:block ml-4 px-4 py-1 rounded-md text-sm min-w-[140px] text-center ${
              Object.values(podcastGenerationStatus).some(status => status.loading)
                ? 'bg-black text-white animate-[pulse_1.5s_ease-in-out_infinite]'
                : 'text-black border border-black'
            }`}>
              {Object.values(podcastGenerationStatus).some(status => status.loading)
                ? 'Generating podcasts...'
                : 'All podcasts ready'}
            </div>
          )}
        </div>
      </div>

      <div 
        ref={carouselRef}
        className="flex space-x-4 overflow-x-auto pb-4 relative"
        style={{
          maskImage: 'linear-gradient(to right, transparent, black var(--left-fade, 0%), black calc(100% - var(--right-fade, 0%)), transparent)',
          WebkitMaskImage: 'linear-gradient(to right, transparent, black var(--left-fade, 0%), black calc(100% - var(--right-fade, 0%)), transparent)',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
        onScroll={handleScroll}
      >
        {validPodcastTypes.map(renderPodcast)}
      </div>

      {/* Description section with PDF links */}
      {hoveredPodcast && (
        <div className="mt-4 w-[85%]">
          <h4 className="font-semibold text-sm mb-1">Description</h4>
          <p className="text-sm text-black mb-2">{currentDescription}</p>
          
          {/* PDF-specific links */}
          {isPdf && hoveredPodcast && (
            <div className="flex flex-wrap gap-4 mt-2">
              {hoveredPodcast.pdfUrl && (
                <a 
                  href={hoveredPodcast.pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-1 text-sm text-black hover:underline border border-black px-2 py-1 rounded"
                >
                  <ExternalLink size={16} className="text-black" />
                  View Original PDF
                </a>
              )}
              {hoveredPodcast.textUrl && (
                <a 
                  href={hoveredPodcast.textUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-1 text-sm text-black hover:underline border border-black px-2 py-1 rounded"
                >
                  <ExternalLink size={16} className="text-black" />
                  View Extracted Text
                </a>
              )}
            </div>
          )}
        </div>
      )}

      {validPodcastTypes.length === 0 && (
        <div className="text-center text-gray-600 py-8 bg-gray-50 rounded-lg">
          <p className="mb-2">No podcasts available yet.</p>
          <p className="text-sm">
            {isPdf ? 'Upload a PDF to generate podcasts' : 'Try a different search query or check back later.'}
          </p>
        </div>
      )}

      {loadingAudioStatus === 'playing' && (
        <div className="fixed bottom-4 right-4 bg-black text-white px-4 py-2 rounded-lg flex items-center gap-2">
          <Volume2 className="animate-pulse" size={20} />
          <span>Playing preview...</span>
        </div>
      )}
    </div>
  );
};

PodcastSection.propTypes = {
  articlesLoaded: PropTypes.bool.isRequired,
  podcastGenerationIds: PropTypes.object
};

PodcastSection.defaultProps = {
  podcastGenerationIds: {}
};

export default React.memo(PodcastSection);