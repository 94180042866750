// Header.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bell, User } from 'lucide-react';
import { useSlideUp, SLIDEUP_IDS, SLIDEUP_TYPES } from '../providers/SlideUpManager';
import FeedlyLogoutButton from './FeedlyLogoutButton';
import SearchButton from './SearchButton';
import SearchField from './SearchField';
import AccountsSlideUp from './slideups/AccountsSlideUp';
import Thelogo from '../assets/logo.svg';

const Header = ({ onLogout, isFeedlyLoggedIn, showLogo = false }) => {
  const navigate = useNavigate();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const { showSlideUp, hideSlideUp, visibleSlideUps } = useSlideUp();

  const handleSearchClick = () => {
    setIsSearchVisible((prev) => !prev);
  };

  const handleAccountClick = () => {
    showSlideUp(SLIDEUP_IDS.ACCOUNTS, SLIDEUP_TYPES.PRIORITY);
  };

  return (
    <>
      <header
        className="sticky top-0 p-4 flex justify-between items-center z-50"
        style={{
          background:
            'linear-gradient(0deg, rgba(181, 181, 181, 0) 0%, rgba(181, 181, 181, 0.859) 15.5%, #b5b5b5 50%, #b5b5b5 100%)',
        }}
      >
        {showLogo && (
          <img
            src={Thelogo}
            alt="Logo"
            className="w-10 h-10 cursor-pointer"
            onClick={() => navigate('/')}
          />
        )}
        {!showLogo && <div className="w-10" />}
        <div className="flex items-center space-x-4">
          <button className="hidden md:block px-3 py-1 border border-black rounded-md font-semibold text-sm">
            What's Next
          </button>
          {isFeedlyLoggedIn && <FeedlyLogoutButton onLogout={onLogout} />}
          <Bell className="w-6 h-6 cursor-pointer" />
          {isSearchVisible && <SearchField />}
          <SearchButton onClick={handleSearchClick} />
          <div 
            className="w-7 h-7 bg-gray-300 rounded-full overflow-hidden cursor-pointer hover:bg-gray-400 transition-colors"
            onClick={handleAccountClick}
          >
            <User className="w-full h-full p-1" />
          </div>
        </div>
      </header>

      <AccountsSlideUp 
        isVisible={visibleSlideUps[SLIDEUP_IDS.ACCOUNTS]} 
        onClose={() => hideSlideUp(SLIDEUP_IDS.ACCOUNTS)} 
      />
    </>
  );
};

export default Header;