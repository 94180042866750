// src/components/Home.js

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Upload, ChevronDown, Globe, MapPin, Newspaper, Link as LinkIcon } from 'lucide-react';
import { useSlideUp, SLIDEUP_IDS, SLIDEUP_TYPES } from '../providers/SlideUpManager';
import FeedlyLogin from './FeedlyLogin';
import FeedlySlideUp from './slideups/FeedlySlideUp';
import SearchLocalSlideUp from './slideups/SearchLocalSlideUp';
import TwitterSlideUp from './slideups/TwitterSlideUp';
import ComposePodcastSlideUp from './slideups/ComposePodcastSlideUp';
import Header from './Header';
import {
 setLoading,
 setError,
 clearSearchResults,
 clearDynamicPodcasts,
 setDynamicPodcasts,
 setArticles,
 clearFeedlyData,
 clearFeedlyArticles,
 setIsFeedlyContent,
 setSearchType,
 setPdfFile,
 clearComposeParameters,
 selectComposeParameters,
 setComposeInstructions,
 setComposeParameters
} from '../redux/store';
import Thelogo from '../assets/logo.svg';
import ArrowRightImage from '../assets/images/right.svg';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const LOCATION_PERMISSION_KEY = 'locationPermissionGranted';

// Add this helper function before the Home component
const isValidUrl = (string) => {
 try {
   new URL(string);
   return true;
 } catch (_) {
   return false;
 }
};

const SearchField = ({ 
 searchTerm, 
 setSearchTerm, 
 onSearch, 
 placeholder,
 variant = 'standard',
 type = 'text',
 disabled = false
}) => {
 const onChange = (event) => {
   setSearchTerm(event.target.value);
   
   // Auto-adjust height for URL input
   if (variant === 'thin' && event.target.scrollHeight > 0) {
     event.target.style.height = 'auto';
     event.target.style.height = `${event.target.scrollHeight}px`;
   }
 };

 const onKeyDown = (event) => {
   if (event.key === 'Enter' && !event.shiftKey) {
     event.preventDefault();
     onSearch();
   }
 };

 if (variant === 'thin') {
   return (
     <div className="relative w-full">
       <textarea
         type={type}
         placeholder={placeholder}
         onChange={onChange}
         onKeyDown={onKeyDown}
         value={searchTerm}
         disabled={disabled}
         rows={1}
         className={`w-full p-3 text-base border-2 border-black rounded-lg outline-none min-h-[48px] overflow-hidden resize-none ${
           disabled ? 'bg-gray-100 cursor-not-allowed' : ''
         }`}
         style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}
       />
       <button 
         onClick={onSearch} 
         disabled={disabled}
         className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer hover:opacity-75 transition-opacity ${
           disabled ? 'opacity-50 cursor-not-allowed' : ''
         }`}
       >
         <img src={ArrowRightImage} alt="Arrow Right" width={24} height={24} />
       </button>
     </div>
   );
 }

 return (
   <div className="relative w-full">
     <textarea
       placeholder={placeholder}
       onChange={onChange}
       onKeyDown={onKeyDown}
       value={searchTerm}
       disabled={disabled}
       rows={5}
       className={`w-full p-4 text-base border-2 border-black rounded-lg resize-none outline-none ${
         disabled ? 'bg-gray-100 cursor-not-allowed' : ''
       }`}
     />
     <button 
       onClick={onSearch}
       disabled={disabled}
       className={`absolute bottom-4 right-4 cursor-pointer hover:opacity-75 transition-opacity ${
         disabled ? 'opacity-50 cursor-not-allowed' : ''
       }`}
     >
       <img src={ArrowRightImage} alt="Arrow Right" width={30} height={30} />
     </button>
   </div>
 );
};

const FileUpload = ({ onUpload }) => {
 const handleDragOver = (e) => {
   e.preventDefault();
 };

 const handleDrop = (e) => {
   e.preventDefault();
   const files = e.dataTransfer.files;
   if (files.length && files[0].type === 'application/pdf') {
     onUpload(files[0]);
   }
 };

 const handleFileSelect = (e) => {
   const files = e.target.files;
   if (files.length && files[0].type === 'application/pdf') {
     onUpload(files[0]);
   }
 };

 return (
   <div className="relative w-full">
     <div
       onDragOver={handleDragOver}
       onDrop={handleDrop}
       className="w-full p-4 text-base border-2 border-black border-dashed rounded-lg resize-none outline-none min-h-[160px] flex items-center justify-center cursor-pointer relative"
     >
       <input
         type="file"
         onChange={handleFileSelect}
         className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
         accept=".pdf"
       />
       <span className="text-gray-500">Drag and drop your PDF here or click to browse</span>
       <div className="absolute bottom-4 right-4 cursor-pointer hover:opacity-75 transition-opacity">
         <Upload size={30} />
       </div>
     </div>
   </div>
 );
};

const DropdownButton = ({ 
 label,
 options,
 selectedOption,
 onOptionSelect,
 isSelected,
 icon: Icon
}) => {
 const [isDropdownOpen, setIsDropdownOpen] = useState(false);
 const dropdownRef = useRef(null);

 useEffect(() => {
   const handleClickOutside = (event) => {
     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
       setIsDropdownOpen(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => document.removeEventListener('mousedown', handleClickOutside);
 }, []);

 return (
   <div className="relative" ref={dropdownRef}>
     <button
       onClick={() => setIsDropdownOpen(!isDropdownOpen)}
       className={`px-4 py-2 border border-black rounded-md font-semibold text-sm transition-colors flex items-center space-x-2 ${
         isSelected ? 'bg-black text-white' : 'bg-transparent hover:bg-black hover:text-white'
       }`}
     >
       {Icon && <Icon className="w-4 h-4 mr-2" />}
       <span>{label}</span>
       <ChevronDown className="w-4 h-4" />
     </button>
     
     {isDropdownOpen && (
       <div className="absolute z-10 mt-1 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
         {options.map((option) => (
           <button
             key={option.value}
             onClick={() => {
               onOptionSelect(option);
               setIsDropdownOpen(false);
             }}
             className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 transition-colors flex items-center"
           >
             {option.icon && <option.icon className="w-4 h-4 mr-2" />}
             {option.label}
           </button>
         ))}
       </div>
     )}
   </div>
 );
};

const ActionButtons = ({ 
 selectedButton,
 searchOption,
 onSearchOptionSelect,
 feedlyOption,
 onFeedlyOptionSelect,
 isFeedlyLoggedIn,
 onTwitterClick,
 onUrlClick,
 onPDFClick,
 onComposePodcastClick
}) => {
 const searchOptions = [
   { 
     value: 'news', 
     label: 'News Search', 
     placeholder: 'Enter what you want to know in the news...',
     icon: Newspaper
   },
   { 
     value: 'organic', 
     label: 'Web Search', 
     placeholder: 'Enter anything to search the web...',
     icon: Globe
   }, 
   { 
     value: 'local', 
     label: 'Local Search', 
     placeholder: 'Enter keywords for local search...',
     icon: MapPin
   }
 ];

 const feedlyOptions = [
   { 
     label: 'Feedly Article Search', 
     value: 'search',
     placeholder: 'Search Feedly articles...'
   },
   { 
     label: 'Feedly Categories', 
     value: 'categories',
     placeholder: 'Browse Feedly categories...'
   }
 ];

 return (
   <div className="flex flex-wrap gap-y-3 gap-x-4 mt-4">
     <DropdownButton
       label={searchOption.label}
       options={searchOptions}
       selectedOption={searchOption}
       onOptionSelect={onSearchOptionSelect}
       isSelected={selectedButton === 'search'}
       icon={
         searchOption.value === 'local' 
           ? MapPin 
           : searchOption.value === 'global' 
             ? Globe 
             : Newspaper
       }
     />
     
     <DropdownButton
       label={feedlyOption.label}
       options={feedlyOptions}
       selectedOption={feedlyOption}
       onOptionSelect={onFeedlyOptionSelect}
       isSelected={selectedButton === 'feedly'}
     />

     <button
       onClick={onTwitterClick}
       className={`px-4 py-2 border border-black rounded-md font-semibold text-sm transition-colors ${
         selectedButton === 'twitter' ? 'bg-black text-white' : 'bg-transparent hover:bg-black hover:text-white'
       }`}
     >
       Twitter
     </button>

     <button
       onClick={onUrlClick}
       className={`px-4 py-2 border border-black rounded-md font-semibold text-sm transition-colors ${
         selectedButton === 'url' ? 'bg-black text-white' : 'bg-transparent hover:bg-black hover:text-white'
       }`}
     >
       URL
     </button>

     <button
       onClick={onPDFClick}
       className={`px-4 py-2 border border-black rounded-md font-semibold text-sm transition-colors ${
         selectedButton === 'pdf' ? 'bg-black text-white' : 'bg-transparent hover:bg-black hover:text-white'
       }`}
     >
       PDF
     </button>

     <button
       onClick={onComposePodcastClick}
       className={`px-4 py-2 border border-black rounded-md font-semibold text-sm transition-colors ${
         selectedButton === 'compose' ? 'bg-black text-white' : 'bg-transparent hover:bg-black hover:text-white'
       }`}
     >
       Compose Podcast
     </button>
   </div>
 );
};

const Home = () => {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const feedlyUser = useSelector((state) => state.news.feedlyUser);
 const composeParameters = useSelector(selectComposeParameters);
 const [feedlyLoginFunc, setFeedlyLoginFunc] = useState(null);
 const [searchTerm, setSearchTerm] = useState('');
 const [activeField, setActiveField] = useState('search');
 const [selectedButton, setSelectedButton] = useState('search');
 const [userLocation, setUserLocation] = useState(null);
 const [locationPermissionGranted, setLocationPermissionGranted] = useState(
   localStorage.getItem(LOCATION_PERMISSION_KEY) === 'true'
 );
 
 const { showSlideUp, hideSlideUp, visibleSlideUps } = useSlideUp();

 const [searchOption, setSearchOption] = useState({ 
   label: 'Search News', 
   value: 'news',
   icon: Newspaper,
   placeholder: 'Enter what you want to know in the news...'
 });

 const [feedlyOption, setFeedlyOption] = useState({ 
   label: 'Feedly Articles', 
   value: 'search',
   placeholder: 'Search Feedly articles...'
 });

 const requestLocationPermission = async () => {
   if (!locationPermissionGranted && 'geolocation' in navigator) {
     try {
       const position = await new Promise((resolve, reject) => {
         navigator.geolocation.getCurrentPosition(resolve, reject);
       });
       
       const geocodingResponse = await fetch(
         `https://api.opencagedata.com/geocode/v1/json?q=${position.coords.latitude}+${position.coords.longitude}&key=YOUR_API_KEY`
       );
       const geocodingData = await geocodingResponse.json();
       const locationName = geocodingData.results[0].formatted;
       
       setUserLocation(locationName);
       localStorage.setItem(LOCATION_PERMISSION_KEY, 'true');
       setLocationPermissionGranted(true);
       return locationName;
     } catch (error) {
       console.error('Location permission denied or error occurred:', error);
       setLocationPermissionGranted(false);
       localStorage.removeItem(LOCATION_PERMISSION_KEY);
       
       if (searchOption.value === 'local') {
         setSearchOption({ 
           label: 'Search News', 
           value: 'news',
           icon: Newspaper,
           placeholder: 'Enter keywords to search news...'
         });
       }
       return null;
     }
   }
   return userLocation;
 };

 useEffect(() => {
   if (searchOption.value === 'local' && !locationPermissionGranted) {
     requestLocationPermission();
   }
 }, [searchOption.value, locationPermissionGranted]);

 const handleLoginClick = useCallback((loginFunc) => {
   setFeedlyLoginFunc(() => loginFunc);
 }, []);

 const handleLoginSuccess = useCallback(() => {
   showSlideUp(SLIDEUP_IDS.FEEDLY, SLIDEUP_TYPES.PRIORITY);
 }, [showSlideUp]);

 const handleSearchOptionSelect = async (option) => {
   setSearchOption(option);
   setSelectedButton('search');
   setActiveField('search');
   setSearchTerm('');

   if (option.value === 'local') {
     showSlideUp(SLIDEUP_IDS.LOCAL_SEARCH, SLIDEUP_TYPES.INFO);
     await requestLocationPermission();
   }
 };

 const handleFeedlyOptionSelect = (option) => {
   setFeedlyOption(option);
   setSelectedButton('feedly');
   
   if (option.value === 'categories') {
     setActiveField('categories');
     setSearchTerm('');
     if (feedlyUser.token) {
       showSlideUp(SLIDEUP_IDS.FEEDLY, SLIDEUP_TYPES.PRIORITY);
     } else if (feedlyLoginFunc) {
      feedlyLoginFunc();
     }
   } else {
     setActiveField('search');
   }
 };

 const handleTwitterClick = () => {
   setSelectedButton('twitter');
   setActiveField('search');
   setSearchTerm('');
   showSlideUp(SLIDEUP_IDS.TWITTER, SLIDEUP_TYPES.INFO);
 };

 const handleUrlClick = () => {
   setSelectedButton('url');
   setActiveField('url');
   setSearchTerm('');
 };

 const handlePDFClick = () => {
   setSelectedButton('pdf');
   setActiveField('pdf');
   setSearchTerm('');
 };

 const handleComposePodcastClick = () => {
   setSelectedButton('compose');
   setActiveField('search');
   setSearchTerm('');
   showSlideUp(SLIDEUP_IDS.COMPOSE_PODCAST, SLIDEUP_TYPES.PRIORITY);
 };

 const handleFileUpload = (file) => {
   if (!file) return;

   dispatch(setPdfFile(file));
   
   const searchParams = new URLSearchParams();
   searchParams.append('searchType', 'pdf');
   
   navigate(`/results?${searchParams.toString()}`);
 };

 const handleLogout = () => {
   window.localStorage.removeItem('feedly_token');
   window.localStorage.removeItem('feedly_refresh');
   window.localStorage.removeItem('feedly_id');
   dispatch(clearFeedlyData());
   dispatch(setIsFeedlyContent(false));
   hideSlideUp(SLIDEUP_IDS.FEEDLY);
   setFeedlyOption({ 
     label: 'Feedly Articles', 
     value: 'search',
     placeholder: 'Search Feedly articles...'
   });
 };

 const handleSearch = async () => {
  if (!searchTerm.trim()) {
    dispatch(setError('Please enter a valid search term.'));
    dispatch(setLoading(false));
    return;
  }

  dispatch(setLoading(true));
  dispatch(clearSearchResults());
  dispatch(clearDynamicPodcasts());
  dispatch(setIsFeedlyContent(false));

  const searchParams = new URLSearchParams();
  searchParams.append('q', searchTerm.trim());

  // Handle compose podcast type
  if (selectedButton === 'compose') {
    // Always use the defaults from ComposePodcastSlideUp
    const defaultParameters = {
      type: 'conversational',
      audience: 'general audience',
      duration: 'medium',
      tone: 'semi-professional'
    };

    // Merge with any existing parameters from Redux
    const finalParameters = {
      ...defaultParameters,
      ...(composeParameters || {})  // Only merge if composeParameters exists
    };

    dispatch(setComposeInstructions(searchTerm.trim()));
    dispatch(setComposeParameters(finalParameters));

    const searchParams = new URLSearchParams();
    searchParams.append('searchType', 'compose');
    searchParams.append('q', searchTerm.trim());
    searchParams.append('instructions', searchTerm.trim());
    
    // Ensure all parameters are sent
    Object.entries(finalParameters).forEach(([key, value]) => {
      searchParams.append(key, value);
    });

    navigate(`/results?${searchParams.toString()}`);
    return;
  }

   // Handle URL search
   if (selectedButton === 'url') {
     if (!isValidUrl(searchTerm)) {
       dispatch(setError('Please enter a valid URL'));
       dispatch(setLoading(false));
       return;
     }
     searchParams.append('searchType', 'url');
     navigate(`/results?${searchParams.toString()}`);
     return;
   }

   // Handle other search types
   switch (searchOption.value) {
     case 'news':
       navigate(`/results?${searchParams.toString()}`);
       break;

     case 'local':
       if (!userLocation) {
         dispatch(setError('Location is required for local search.'));
         dispatch(setLoading(false));
         return;
       }
       searchParams.append('searchType', 'local');
       searchParams.append('location', userLocation);
       navigate(`/results?${searchParams.toString()}`);
       break;

     case 'organic':
       searchParams.append('searchType', 'organic');
       navigate(`/results?${searchParams.toString()}`);
       break;

     default:
       dispatch(setError('Invalid search type'));
       dispatch(setLoading(false));
   }
 };

 const getPlaceholderText = () => {
   switch (selectedButton) {
     case 'url':
       return 'Enter a URL to process';
     case 'feedly':
       return feedlyOption.placeholder;
     case 'searchNews':
       return 'Enter what you want to know in the news...';
     case 'twitter':
       return 'Enter keywords to search tweets...';
     case 'compose':
       return 'Enter your podcast instructions here...';
     default:
       return searchOption.placeholder;
   }
 };

 const getSearchField = () => {
   if (activeField === 'pdf') {
     return <FileUpload onUpload={handleFileUpload} />;
   }

   const isDisabled = selectedButton === 'feedly' && feedlyOption.value === 'categories';

   return (
     <SearchField 
       searchTerm={searchTerm} 
       setSearchTerm={setSearchTerm} 
       onSearch={handleSearch} 
       placeholder={getPlaceholderText()}
       variant={selectedButton === 'url' ? 'thin' : 'standard'}
       type={selectedButton === 'url' ? 'url' : 'text'}
       disabled={isDisabled}
     />
   );
 };

 const isFeedlyLoggedIn = !!feedlyUser.token;

 return (
   <div className="flex flex-col min-h-screen bg-[#b5b5b5]">
     <Header 
       onLogout={handleLogout} 
       isFeedlyLoggedIn={isFeedlyLoggedIn}
       showLogo={false}
     />

     <main className="flex-grow flex items-start justify-center p-4">
       <div className="w-full max-w-2xl text-center">
         <img
           src={Thelogo}
           alt="Logo"
           className="mx-auto mb-12 w-36 h-36 object-contain"
         />
         
         {getSearchField()}
         
         <ActionButtons 
           selectedButton={selectedButton}
           searchOption={searchOption}
           onSearchOptionSelect={handleSearchOptionSelect}
           feedlyOption={feedlyOption}
           onFeedlyOptionSelect={handleFeedlyOptionSelect}
           isFeedlyLoggedIn={isFeedlyLoggedIn}
           onTwitterClick={handleTwitterClick}
           onUrlClick={handleUrlClick}
           onPDFClick={handlePDFClick}
           onComposePodcastClick={handleComposePodcastClick}
         />

         <FeedlySlideUp 
           isVisible={visibleSlideUps[SLIDEUP_IDS.FEEDLY]} 
           onClose={() => hideSlideUp(SLIDEUP_IDS.FEEDLY)} 
         />

         <SearchLocalSlideUp 
           isVisible={visibleSlideUps[SLIDEUP_IDS.LOCAL_SEARCH]} 
           onClose={() => hideSlideUp(SLIDEUP_IDS.LOCAL_SEARCH)} 
         />

         <TwitterSlideUp 
           isVisible={visibleSlideUps[SLIDEUP_IDS.TWITTER]} 
           onClose={() => hideSlideUp(SLIDEUP_IDS.TWITTER)} 
         />

         <ComposePodcastSlideUp 
           isVisible={visibleSlideUps[SLIDEUP_IDS.COMPOSE_PODCAST]} 
           onClose={() => hideSlideUp(SLIDEUP_IDS.COMPOSE_PODCAST)} 
         />

         <div className="mt-4">
           <FeedlyLogin 
             onLoginClick={handleLoginClick}
             onLoginSuccess={handleLoginSuccess}
           />
         </div>
       </div>
     </main>
   </div>
 );
};


export default Home;