import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useLoadingAudio } from '../../results/hooks/useLoadingAudio';
import { 
  addDynamicPodcast, 
  setError,
  resetAudioState,
  setAudioDuration,
  setAudioUrl,
  setPlayingState,
  selectIsPlaying,
  selectCurrentPodcast,
  ADD_DYNAMIC_PODCAST
} from '../../../redux/store';
import { io } from 'socket.io-client';
import { usePodcastPlayback } from './usePodcastPlayback';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : 'https://backend-dev.podsi.xyz';

const MAX_POLL_ATTEMPTS = 10;
const POLL_INTERVAL = 5000;

const PODCAST_TYPES = {
  MAIN: 'mainPodcast',
  TOP_NEWS: 'topNewsPodcast',
  CONVERSATIONAL: 'conversationalPodcast',
  TRENDING: 'trendingNewsPodcast',
  TOPIC_OVERVIEW: 'topicOverviewPodcast'
};

const PODCAST_TYPE_ORDER = {
  url: [
    'conversationalPodcast',
    'topNewsPodcast', 
    'trendingNewsPodcast',
    'topicOverviewPodcast'
  ],
  compose: [
    'conversationalPodcast',
    'topicOverviewPodcast',
    'trendingNewsPodcast'
  ],
  default: [
    'topNewsPodcast',
    'conversationalPodcast', 
    'trendingNewsPodcast',
    'topicOverviewPodcast'
  ]
};

const SOCKET_EVENTS = {
  PODCAST_READY: (type) => `podcastReady_${type}`,
  PODCAST_ERROR: 'podcastError',
  CONNECTION_STATUS: 'connectionStatus',
  ARTICLES_READY: 'articlesReady'
};

const DEBUG_MODE = true;

const debugLog = (...args) => {
  if (DEBUG_MODE) {
    console.log(...args);
  }
};

export const usePodcastGeneration = (
  articlesLoaded,
  isFeedlyContent,
  feedlyToken,
  podcastGenerationIds
) => {
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const activePolls = useRef(new Map());
  const initialPollStarted = useRef(false);
  const socket = useRef(null);
  const previousPodcastRef = useRef(null);
  const [socketStatus, setSocketStatus] = useState('disconnected');
  const [podcastGenerationStatus, setPodcastGenerationStatus] = useState({});
  const pendingMainPodcastRef = useRef(null);

  const getCurrentSearchType = useCallback(() => {
    return new URLSearchParams(window.location.search).get('searchType') || 'default';
  }, []);

  const getPodcastOrder = useCallback((type) => {
    const searchType = getCurrentSearchType();
    const orderArray = PODCAST_TYPE_ORDER[searchType] || PODCAST_TYPE_ORDER.default;
    return orderArray.indexOf(type);
  }, [getCurrentSearchType]);

  const {
    loadingPodcastId,
    setLoadingPodcastId,
    loadingAudioStatus,
    handlePodcastPlay,
    handlePodcastPause,
    isPlaying,
    currentPodcast,
    stopLoadingAudio
  } = usePodcastPlayback(getCurrentSearchType, getPodcastOrder);

  const persistPodcastState = useCallback(() => {
    if (currentPodcast) {
      const state = {
        podcast: {
          ...currentPodcast,
          order: getPodcastOrder(currentPodcast.type)
        },
        status: podcastGenerationStatus,
        socketId: socket.current?.id
      };
      sessionStorage.setItem('lastPodcastState', JSON.stringify(state));
    }
  }, [currentPodcast, podcastGenerationStatus, getPodcastOrder]);

  const reconnectSocket = useCallback(() => {
    if (socket.current?.disconnected) {
      debugLog('Attempting to reconnect socket...');
      socket.current.connect();
    }
  }, []);

  useEffect(() => {
    mounted.current = true;
    
    const savedState = sessionStorage.getItem('lastPodcastState');
    if (savedState) {
      const { podcast, status, socketId } = JSON.parse(savedState);
      setPodcastGenerationStatus(status);
      dispatch(addDynamicPodcast({
        ...podcast,
        order: getPodcastOrder(podcast.type)
      }));
      
      if (socketId && socket.current?.id !== socketId) {
        reconnectSocket();
      }
    }

    return () => {
      mounted.current = false;
      activePolls.current.forEach(timeout => clearTimeout(timeout));
      activePolls.current.clear();
      initialPollStarted.current = false;
      
      if (typeof stopLoadingAudio === 'function') {
        stopLoadingAudio();
      }
      
      pendingMainPodcastRef.current = null;
      
      if (!window.location.pathname.includes('/results')) {
        if (socket.current) {
          socket.current.disconnect();
          socket.current = null;
        }
        sessionStorage.removeItem('lastPodcastState');
      }
    };
  }, [dispatch, stopLoadingAudio, reconnectSocket, getPodcastOrder]);

  const checkAllPodcastsReady = useCallback((status) => {
    const allTypes = Object.keys(status);
    return allTypes.length > 0 && allTypes.every(type => !status[type].loading);
  }, []);

  useEffect(() => {
    if (socketStatus === 'disconnected') {
      const reconnectTimer = setTimeout(reconnectSocket, 5000);
      return () => clearTimeout(reconnectTimer);
    }
  }, [socketStatus, reconnectSocket]);

  useEffect(() => {
    if (podcastGenerationIds && Object.keys(podcastGenerationIds).length > 0) {
      activePolls.current.forEach(timeout => clearTimeout(timeout));
      activePolls.current.clear();
      initialPollStarted.current = false;
      
      debugLog('Initializing podcast generation status:', podcastGenerationIds);
      const initialStatus = Object.entries(podcastGenerationIds).reduce((acc, [type, id]) => ({
        ...acc,
        [type]: {
          generationId: id,
          loading: true,
          error: false,
          retryCount: 0,
          order: getPodcastOrder(type)
        }
      }), {});
      setPodcastGenerationStatus(initialStatus);
    }
  }, [podcastGenerationIds, getPodcastOrder]);

  const handleError = useCallback((error, message) => {
    debugLog(message, error);
    if (mounted.current) {
      dispatch(setError(error.message || message));
    }
  }, [dispatch]);

  const formatArticlesForTTS = useCallback((articles) => {
    return articles.map(article => ({
      title: article.title,
      published: article.published || article.date || new Date().toISOString(),
      source: article.source || "Unknown Source",
      link: article.link || article.url,
      _id: article.id || uuidv4(),
      content: article.content || article.snippet,
      imageUrl: article.imageUrl || article.image
    }));
  }, []);

  const handlePodcastReady = useCallback((type, data) => {
    debugLog(`📻 Received podcast ready for ${type}:`, data);
    if (data.success && data.podcast) {
      const order = getPodcastOrder(type);
      dispatch(addDynamicPodcast({ 
        type, 
        podcast: {
          ...data.podcast,
          order
        }
      }));
      
      setPodcastGenerationStatus(prev => {
        const newStatus = {
          ...prev,
          [type]: {
            ...prev[type],
            loading: false,
            error: false,
            order
          }
        };

        if (checkAllPodcastsReady(newStatus)) {
          setLoadingPodcastId(null);
        }

        return newStatus;
      });
      
      persistPodcastState();
    }
  }, [dispatch, persistPodcastState, getPodcastOrder, checkAllPodcastsReady]);

  useEffect(() => {
    if (!socket.current) {
      socket.current = io(API_BASE_URL, {
        auth: { token: feedlyToken },
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        transports: ['websocket'],
        forceNew: false,
        query: { 
          lastSocketId: sessionStorage.getItem('lastSocketId')
        }
      });

      socket.current.on('connect', () => {
        debugLog('Socket connected:', socket.current.id);
        setSocketStatus('connected');
        sessionStorage.setItem('lastSocketId', socket.current.id);
      });

      socket.current.on('podcastUpdate', (data) => {
        debugLog('Received podcast update:', data);
        if (data.data?.podcast && data.type) {
          handlePodcastReady(data.type, {
            success: true,
            podcast: data.data.podcast
          });
        }
      });

      socket.current.on('podcastError', (data) => {
        debugLog('Podcast generation error:', data);
        if (data.type) {
          setPodcastGenerationStatus(prev => ({
            ...prev,
            [data.type]: {
              ...prev[data.type],
              loading: false,
              error: true,
              errorMessage: data.error
            }
          }));
        }
      });

      socket.current.on('disconnect', () => {
        debugLog('Socket disconnected');
        setSocketStatus('disconnected');
      });

      socket.current.on('connect_error', (error) => {
        debugLog('Socket connection error:', error);
        setSocketStatus('error');
      });
    }

    return () => {
      if (!window.location.pathname.includes('/results')) {
        socket.current?.off('podcastUpdate');
        socket.current?.off('podcastError');
        socket.current?.off('connect');
        socket.current?.off('disconnect');
        socket.current?.off('connect_error');
      }
    };
  }, [dispatch, feedlyToken, handlePodcastReady]);

  useEffect(() => {
    debugLog('Current podcast generation status:', podcastGenerationStatus);
  }, [podcastGenerationStatus]);

  useEffect(() => {
    if (!initialPollStarted.current) {
      debugLog('Starting podcast generation...', {
        podcastGenerationIds,
        articlesLoaded
      });

      const searchType = getCurrentSearchType();
      if (searchType === 'compose') {
        const composeTypes = PODCAST_TYPE_ORDER.compose;
        const initialStatus = composeTypes.reduce((acc, type) => ({
          ...acc,
          [type]: {
            loading: true,
            error: false,
            retryCount: 0,
            order: getPodcastOrder(type)
          }
        }), {});
        setPodcastGenerationStatus(initialStatus);
        initialPollStarted.current = true;
        return;
      }
      
      const podcastTypes = PODCAST_TYPE_ORDER[searchType] || PODCAST_TYPE_ORDER.default;
      const initialStatus = podcastTypes.reduce((acc, type) => ({
        ...acc,
        [type]: {
          loading: true,
          error: false,
          retryCount: 0,
          order: getPodcastOrder(type)
        }
      }), {});
      setPodcastGenerationStatus(initialStatus);
      initialPollStarted.current = true;
    }
  }, [getPodcastOrder, getCurrentSearchType]);

  useEffect(() => {
    if (currentPodcast?.title !== previousPodcastRef.current?.title) {
      debugLog('Podcast changed:', currentPodcast?.title);
      previousPodcastRef.current = currentPodcast;
      persistPodcastState();
    }
  }, [currentPodcast, persistPodcastState]);

  useEffect(() => {
    if (podcastGenerationIds) {
      debugLog('Received generation IDs:', podcastGenerationIds);
    }
  }, [podcastGenerationIds]);

  return {
    podcastGenerationStatus,
    loadingPodcastId,
    setLoadingPodcastId,
    loadingAudioStatus,
    handlePodcastPlay,
    handlePodcastPause,
    socketStatus,
    getCurrentSearchType,
    getPodcastOrder
  };
};

export default usePodcastGeneration;