import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { addBookmark, removeBookmark, selectIsBookmarked } from '../../../redux/store';

export const useBookmarks = (items, type) => {
  const dispatch = useDispatch();

  const selectBookmarks = useMemo(() => 
    createSelector(
      state => state,
      state => {
        // Filter out invalid items
        const validItems = (items || []).filter(item => item && item.id);
        
        return validItems.reduce((acc, item) => ({
          ...acc,
          [item.id]: selectIsBookmarked(type)(state, item.id)
        }), {});
      }
    ),
    [items, type]
  );

  const bookmarks = useSelector(selectBookmarks);

  const handleBookmark = useCallback((item) => {
    // Add validation check
    if (!item?.id) {
      console.warn('Invalid item passed to handleBookmark:', item);
      return;
    }

    const isBookmarked = bookmarks[item.id];
    if (isBookmarked) {
      dispatch(removeBookmark(item.id, type));
    } else {
      dispatch(addBookmark(item, type));
    }
  }, [dispatch, bookmarks, type]);

  return {
    bookmarks,
    handleBookmark
  };
};